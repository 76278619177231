import React from "react";
import { Grid } from "@mui/material";
import { useStyles } from "./HomeScreenCss";
import MediaQuery from "../../components/MediaQuery";
import Header from "../../components/Header";
import ShareCom from "../../components/ShareCom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function HomeScreen() {
    const classes = useStyles()
    let mobileView = MediaQuery()
    return (
        <Grid container component='main' id='#home' className={!mobileView ? 'mobileBg1' : "background"} >
            <Grid item xs={12} md={12} lg={12} >
                <Header />
            </Grid>
            <Grid container item xs={12} lg={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }} >
                {!mobileView &&
                    <Grid item xs={12} lg={12} className={classes.textMobileStyle} style={{
                        width: 'auto', top: '13%'
                    }}  >
                        <div style={{ textAlign: 'center', fontSize: '3rem', lineHeight: 1.5 }}  >
                            <FiberManualRecordIcon htmlColor="#FFF" /> Meet <br />
                            <FiberManualRecordIcon htmlColor="#FFF" /> Dance<br />
                            <FiberManualRecordIcon htmlColor="#FFF" /> Fall in Love
                        </div>
                    </Grid>}
                {mobileView &&
                    <Grid container style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: 20 }} >
                        <Grid item xs={12} lg={12}  >
                            <div style={textStyle} >
                                Meet <br />Dance<br />Fall in Love
                            </div>
                        </Grid>
                        <div style={{ display: 'flex', alignSelf: 'flex-start', marginTop: '40px' }} >
                            <ShareCom />
                        </div>
                    </Grid>}
                {!mobileView &&
                    <Grid item xs={12} lg={12} className={classes.textMobileStyle} style={{
                        width: 'auto', bottom: '100px'
                    }} >
                        <ShareCom />
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

const textStyle = {
    fontSize: 70,
    lineHeight: 1,
    color: "#FFF",
    fontWeight: "bolder",
    textShadow: "8px 5px 1px rgba(0, 0, 0, 0.6)",
    textAlign: 'left',
    lineHeight: 1,
}