import './App.css';
import MainScreen from './app/screens/MainScreen';

function App() {
  return (
    <div>
        <MainScreen/>
    </div>
  );
}

export default App;
