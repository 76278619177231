import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "./AboutUsCss"
import MediaQuery from "../../components/MediaQuery";

export default function AboutUs() {
  const classes = useStyles();
  let mobileView = MediaQuery()
  return (
    <Grid
      container
      component="main"
      id="aboutUs"
      className={!mobileView ? "mobileBg2" : "background2"}
      style={{ backgroundColor: "#DDDDDD", padding: "2%", height: '100vh' }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Grid className={classes.clubDateConnect}>
          Club. Date.
          <br />
          Connect.
        </Grid>
        <Grid lg={4} sx={6} >
          <p style={{ fontSize: '0.8rem' }} >
            Finding a real connection isn’t difficult anymore.
            Clubs are the best place to meet and explore what’s out there. We’ve made it easy for you to meet people IRL. Discover genuine relationships, enjoy shared interests, and have a great time.
          </p>
        </Grid>
        <div className={classes.buttonContainer}>
          <button className={classes.button} onMouseOver={(e) => (e.target.style.backgroundColor = classes.buttonHover.backgroundColor)} onMouseOut={(e) => (e.target.style.backgroundColor = classes.button.backgroundColor)}>
            About Smooz
          </button>
        </div>
      </Grid>
    </Grid>
  );
}
