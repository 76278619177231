import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  box: {
    padding: 20,
  },
  textStyle: {
    fontSize: '3.5rem',
    lineHeight:1,
    color: "#FFF",
    fontWeight: "bolder",
    textShadow: "8px 5px 1px rgba(0, 0, 0, 0.6)",
  },
  textMobileStyle: {
    fontSize: '3.5rem',
    color: "#FFF",
    fontWeight: "bolder",
    textShadow: "8px 5px 1px rgba(0, 0, 0, 0.6)",
  },
  clubDateConnect: {
    fontSize: '3.5rem',
    color: "#725799",
    fontWeight: "bolder",
    WebkitTextStrokeWidth: 5,
    WebkitTextStrokeColor: "#725799",
    textShadow: "4px 4px #000000",
    display: "block",
    padding: 4,
  },
  downloadText: {
    color: "#FFF",
    fontSize: '1.5rem',
    fontWeight: "normal",
  },
  shareLinkContainer: {
    backgroundColor: "#000",
    borderColor: "#FFF",
    borderWidth: 1,
    borderStyle: "solid",
    width: 200,
    marginBottom: 10,
    marginRight: "2%",
    padding: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  subContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' },
  subMobileContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', },
  shareContainer: { display: 'flex', flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', },
  shareMobileContainer: { display: 'flex', flexDirection: 'row', margin: 'auto', justifyContent: 'center', },
});
