import React from "react";
import { Grid } from "@mui/material";
import HomeScreen from "./HomeScreen/HomeScreen";
import Features from "./Features/Features";
import OfferSmooz from "./OfferSmooz/OfferSmooz";
import FooterScreen from "./Footer/FooterScreen";
import AboutUs from "../screens/AboutUs/AboutUs";

export default function MainScreen() {
    return (
        <Grid container component='main' >
            <HomeScreen />
            <AboutUs />
            <Features />
            <OfferSmooz />
            <FooterScreen/>
        </Grid>
    )
}
