import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "./FeaturesCss";
import FeatureCard from "./FeatureCard";
import MediaQuery from "../../components/MediaQuery";

export default function Features() {
  const classes = useStyles();
  let mobileView = MediaQuery()
  return (
    <Grid
      container
      component="main"
      id="features"
      className="bg3"
      style={{ backgroundColor: "#725799", padding: "2%", height: !mobileView ? '100%' : '100vh' }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Grid className={classes.textStyle} style={{ textAlign: "center", fontSize: 50, }}>
          {!mobileView ? `Go beyond dating` : "Go beyond dating"}
        </Grid>
        <Grid
          lg={12}
          xs={12}
          style={{ display: "flex", flexDirection: !mobileView ? "column" : "row", alignItems: 'center', justifyContent: 'center' }}
        >
          {!mobileView && <div style={{ marginTop: '10%' }} />}
          <FeatureCard
            mainImage={require('../../assets/images/GeneralDating.png')}
            heading="General Dating"
            subtext="Found someone you really click with? Go ahead, take the first step. Vibe with them, share your favorite things, and dive in. Make your move, and let the fun begin!"
            sourceImage={require('../../assets/images/TL.png')}
            TL
          />
          {!mobileView && <div style={{ marginTop: '15%' }} />}
          <FeatureCard
            mainImage={require('../../assets/images/ClubDating.png')}
            heading="Club Dating"
            subtext="Dancing at the club doesn’t have to be alone. Club dating is where the fun begins! Meet new people, dance the night away, and let the sparks fly."
            sourceImage={require('../../assets/images/BL.png')}
            BL
          />
          {!mobileView && <div style={{ marginTop: '15%' }} />}
          <FeatureCard
            mainImage={require('../../assets/images/Events.png')}
            heading="Events Near You"
            subtext="Ready to be a part of the hottest events in town? Smooz lets you discover the best events. Another chance for you to meet your potantial partner. *wink*"
            sourceImage={require('../../assets/images/TR.png')}
            TR
            BR
          />
          {!mobileView && <div style={{ marginTop: '10%' }} />}
          <div style={{ marginTop: '10%' }} />
          <div style={{ marginTop: '10%' }} />
        </Grid>
      </Grid>
    </Grid>
  );
}
