import { Grid } from "@mui/material";
import MediaQuery from "./MediaQuery";
import { useState } from "react";

export default function Header() {

    const [underLine, setUnderLine] = useState('')

    const tabsData = [
        {id:1,
            text:"Home",
            link:'#home'
        },
        {
            id:2,
            text:'About us',
            link:'#aboutUs'
        },
        {
            id:3,
            text:'Features',
            link:'#features'
        },
        {
            id:4,
            text:'Download app',
            link:'#download'
        }
    ]

    const mobileView = MediaQuery()

    return (
        <Grid container component='main' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: 'transparent', padding: '2%', justifyContent: 'space-between' }} >
            <Grid item lg={2} xs={12} style={{ display: 'flex' }} >
                <img src={require('../assets/images/logo.png')} style={{ width: 150, height: 50, margin: mobileView ? 0 : 'auto' }} />
            </Grid>
            <Grid item lg={8} xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                {tabsData.map((item) => {
                    return (
                        <Grid key={item.id} lg={2} >
                            <a href={item.link} onClick={() => { }} onMouseEnter={() => setUnderLine(item.id)} onMouseLeave={() => setUnderLine('')} style={{ color: '#FFF', fontWeight: 'normal', textDecorationLine: underLine == item.id ? 'underline' : 'none', cursor: 'pointer', lineHeight: mobileView ? 0 : 3 }} >
                                {item.text}
                            </a>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}