import React from "react";
import MediaQuery from "./MediaQuery";

export default function ShareCom() {

    let mobileView = MediaQuery()

    return (
        <div style={classes.box} >
            <div style={mobileView ? classes.shareContainer : classes.shareMobileContainer}  >
                <div style={!mobileView ? classes.downloadMobileText : classes.downloadText} >Download the App</div>
                <div style={!mobileView ? classes.subMobileContainer : classes.subContainer} >
                    <div style={classes.shareLinkContainer} >
                        <img src={require('../assets/images/playstore.png')} style={{ width: 30, height: 30 }} />
                        <div>
                            <div style={{ fontSize: 10, fontWeight: 'lighter', color: '#FFF' }} >
                                GET IT ON
                            </div>
                            <div style={{ fontSize: 20, fontWeight: 'lighter', color: '#FFF' }} >
                                Google Play
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: 20 }} />
                    <div style={classes.shareLinkContainer} >
                        <img src={require('../assets/images/appleIcon.png')} style={{ width: 30, height: 30 }} />
                        <div>
                            <div style={{ fontSize: 10, fontWeight: 'lighter', color: '#FFF' }} >
                                Download on the
                            </div>
                            <div style={{ fontSize: 20, fontWeight: 'lighter', color: '#FFF' }} >
                                App Store
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export const classes = ({
    box: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    textStyle: {
        fontSize: 70,
        lineHeight: 1,
        color: "#FFF",
        fontWeight: "bolder",
    },
    textMobileStyle: {
        display: 'flex', justifyContent: 'center', alignSelf: 'center', height: '30vh',
        fontSize: 70,
        color: "#FFF",
        fontWeight: "bolder",
    },
    downloadText: {
        color: "#FFF",
        fontSize: '2rem',
        fontWeight: "normal",
    },
    downloadMobileText:{
        color: "#FFF",
        fontSize: '2rem',
        fontWeight: "normal",
        textAlign:'center',
    },
    shareLinkContainer: {
        backgroundColor: "#000",
        borderColor: "#FFF",
        borderWidth: 1,
        borderStyle: "solid",
        width: 200,
        marginBottom: 10,
        marginRight: "2%",
        padding: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        cursor: 'pointer'
    },
    subContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' },
    subMobileContainer: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',},
    shareContainer: { display: 'flex', flexDirection: 'column', margin: 'auto', justifyContent: 'space-between', alignItems: 'flex-start' },
    shareMobileContainer: { display: 'flex', flexDirection: 'column', margin: 'auto', justifyContent: 'center',overflowX: 'hidden'},
});
