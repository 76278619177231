import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
      position: 'relative',
      margin:10,
      padding:10,
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      translate: "2% 1%",
      width: 250,
    },
    media: {
      height: 140,
    },
    styleImage: {
      position: 'absolute',
      width: '50px',
      height: '50px',
    },
    TL: {
      top: '-30px',
      left: '-30px',
      zIndex: 999,
      background:'transparent',
      borderWidth:0
    },
    TR: {
      top: '-40px',
      right: '-90px',
      zIndex: 999,
      background:'transparent',
      borderWidth:0
    },
    BL: {
      bottom: '-60px',
      left: '-30px',
      zIndex: 999,
      background:'transparent',
      borderWidth:0
    },
    BR: {
      bottom: '-70px',
      right: '30px',
      zIndex: 999,
      background:'transparent',
    },
  }));
  
  