import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "./FooterCss";
import MediaQuery from "../../components/MediaQuery";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';

const iconColor = "#FFFFFF"

const shareDate = [
  {
    id: 1,
    img: <FacebookOutlinedIcon htmlColor={iconColor} />,
  },
  {
    id: 2,
    img: <InstagramIcon htmlColor={iconColor} />,
  },
  {
    id: 3,
    img: <YouTubeIcon htmlColor={iconColor} />,
  },
  {
    id: 4,
    img: <LinkedInIcon htmlColor={iconColor} />,
  },
  {
    id: 5,
    image: require("../../assets/images/spotify.png"),
  },
  {
    id: 6,
    img: <XIcon htmlColor={iconColor} />,
  },
];

const logsData = [
  { id: 1, text: "About Us", link: '#aboutUs' },
  { id: 2, text: "Features", link: "#features" },
  { id: 3, text: "Terms", link: '' },
  { id: 4, text: "Privacy Policy", link: '' }
];

export default function FooterScreen() {
  const classes = useStyles();

  let mobileView = MediaQuery()

  return (
    <Grid
      container
      id="download"
      component="main"
      style={{ backgroundColor: "#725799", padding: "3%" }}
    >
      {!mobileView &&
        <Grid item lg={2} xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 50, marginBottom: 10 }} >
          <img
            src={require("../../assets/images/logo.png")}
            style={{ width: '50%', height: 60 }}
          />
          <div style={{ color: "#DDDDDD", fontSize: 15, fontWeight: "lighter", marginTop: 20, marginBottom: 20 }}>
            Club.Consent.Connect
          </div>
        </Grid>}
      <Grid
        item
        xs={12}
        lg={10}
        style={{ display: "flex", flexDirection: "column", alignItems: mobileView ? 'flex-start' : 'center', justifyContent: 'center' }}
      >
        <div style={{ color: "#D9D9D9", fontSize: 30, fontWeight: "bold", marginTop: mobileView ? 0 : 30, marginBottom: mobileView ? 10 : 30 }}>
          Join Us Today!
        </div>
        <Grid container style={{ marginBottom: mobileView ? 0 : 30, display: 'flex', flexDirection: 'row', justifyContent: mobileView ? 'flex-start' : 'space-around' }} >
          <Grid item xs={5.5} lg={2.5} className={classes.shareLinkContainer}>
            <img
              src={require("../../assets/images/playstore.png")}
              style={{ width: 30, height: 30 }}
            />
            <div>
              <Grid
                style={{ fontSize: '0.6rem', fontWeight: "lighter", color: "#FFF" }}
              >
                GET IT ON
              </Grid>
              <Grid
                style={{ fontSize: '1rem', fontWeight: "lighter", color: "#FFF" }}
              >
                Google Play
              </Grid>
            </div>
          </Grid>
          {mobileView && <div style={{ marginLeft: 20 }} />}
          <Grid item xs={5.5} lg={2.5} className={classes.shareLinkContainer}>
            <img
              src={require("../../assets/images/appleIcon.png")}
              style={{ width: 30, height: 30 }}
            />
            <div>
              <Grid
                style={{ fontSize: '0.6rem', fontWeight: "lighter", color: "#FFF" }}
              >
                Download on the
              </Grid>
              <Grid
                style={{ fontSize: '1rem', fontWeight: "lighter", color: "#FFF" }}
              >
                App Store
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div style={{ marginBottom: mobileView ? 20 : 30, marginTop: mobileView ? 20 : 30, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ fontSize: 10, fontWeight: "lighter", color: "#FFF" }}>
            Apple and the Apple Logo are trademarks of Apple Inc.
          </div>
          <div style={{ fontSize: 10, fontWeight: "lighter", color: "#FFF" }}>
            Google Play and the Google Play logo are trademarks of Google LLC.
          </div>
        </div>
      </Grid>
      {mobileView &&
        <Grid item lg={2} xs={12}>
          <img
            src={require("../../assets/images/logo.png")}
            style={{ width: '100%', height: 60 }}
          />
          <div style={{ color: "#DDDDDD", fontSize: 15, fontWeight: "lighter" }}>
            Club.Consent.Connect
          </div>
        </Grid>}
      <Grid item lg={12} xs={12} className={classes.divider} style={{ marginTop: 10, marginBottom: 10 }} />
      <div style={{ marginTop: 10 }} />
      <div
        className={classes.flexCenter}
      >
        <Grid item lg={4}>
          <div
            style={{
              color: "#FFF",
              fontSize: 25,
              fontWeight: "normal",
              textDecorationLine: "underline",
              marginTop: 10,
              textUnderlinePosition: "under",
              textDecorationThickness: 1,
            }}
          >
            Offer a Soomz today
          </div>
        </Grid>
      </div>
      <div className={classes.flexCenter}>
        <Grid item lg={12} xs={12} className={classes.socialContainer}>
          {shareDate.map((item) => {
            return (
              <div key={item.id} style={{ marginInline: 15 }} >
                {item.img}
                {item.image && <img src={item.image} style={{ width: 20, height: 20 }} />}
              </div>
            );
          })}
        </Grid>
      </div>
      <Grid item lg={12} xs={12} className={classes.footerText} style={{ marginBottom: mobileView ? 20 : 50, marginTop: mobileView ? 10 : 30 }} >
        <div
          style={{
            textAlign: "center",
            fontSize: 10,
            fontWeight: "normal",
            color: "#FFF",
          }}
        >
          We value your privacy. We and our partners use trackers to measure the{" "}
          <br /> audience of our website and to provide you with offers and
          improve our
          <br /> own Smooz marketing operations.
        </div>
      </Grid>
      <Grid item lg={12} xs={12} className={classes.logStyle}  >
        {logsData.map((item) => {
          return (
            <a
              key={item.id}
              href={item.link}
              style={{
                color: "#FFF",
                fontSize: !mobileView ? 15 : 20,
                fontWeight: "normal",
                textDecorationLine: "underline",
                textUnderlinePosition: "under",
                textDecorationThickness: 1,
                cursor: 'pointer'
              }}
            >
              {item.text}
            </a>
          );
        })}
      </Grid>
      <Grid item lg={12} xs={12} className={classes.logStyle} style={{ marginTop: mobileView ? 40 : 30, marginBottom: mobileView ? -20 : 20 }} >
        <div style={{ color: "#FFF", fontSize: mobileView ? 15 : 10, textAlign: 'center' }}>
          Copyright © 2024 – present. Smooz. All rights reserved.
        </div>
      </Grid>
    </Grid>
  );
}
