import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './FeaturesCardCss';
import '../../../App.css';

export default function FeatureCard({ mainImage, heading, subtext, sourceImage, TL, TR, BL, BR }) {
  const classes = useStyles();
  return (
    <Grid item style={{ backgroundColor: '#000', boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)", width: 250, marginInline: 60, position: 'relative' }}>
      {TL && <img src={sourceImage} alt="absolute" style={{ width: 100, height: 100 }} className={`${classes.styleImage} ${classes.TL}`} />}
      {TR && <img src={sourceImage} alt="absolute" style={{ width: 100, height: 100 }} className={`${classes.styleImage} ${classes.TR}`} />}
      {BL && <img src={sourceImage} alt="absolute" style={{ width: 100, height: 100 }} className={`${classes.styleImage} ${classes.BL}`} />}
      {BR && <img src={require('../../assets/images/love.png')} alt="absolute" style={{ width: 100, height: 100 }} className={`${classes.styleImage} ${classes.BR}`} />}
      <Card className={classes.mainContainer}>
        <CardMedia
          className={classes.media}
          image={mainImage}
          title={heading}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" style={{ color: '#725799', fontFamily: 'Poppins, sans-serif' }} >
            {heading}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" style={{ color: '#333333', fontFamily: 'Poppins, sans-serif' }} >
            {subtext}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

