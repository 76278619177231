import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  box: {
    padding: "3%",
  },
  textStyle: {
    fontSize: 70,
    color: "#FFF",
    fontWeight: "bolder",
    textShadow: "8px 5px 1px rgba(0, 0, 0, 0.6)",
  },

  clubDateConnect: {
    fontSize: 70,
    color: "#725799",
    fontWeight: "bolder",
    WebkitTextStrokeWidth: 5,
    WebkitTextStrokeColor: "#725799",
    textShadow: "4px 4px #000000",
    display: "block",
    padding: 4,
  },
  downloadText: {
    color: "#FFF",
    fontSize: 30,
    fontWeight: "normal",
  },
  shareLinkContainer: {
    backgroundColor: "#000",
    borderColor: "#FFF",
    borderWidth: 1,
    borderStyle: "solid",
    width: 200,
    marginBottom: 10,
    marginRight: "2%",
    padding: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },

  divider: {
    backgroundColor: "#DDDDDD",
    height: 1,
    width: "100%",

  },

  socialContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    borderColor: "#DDDDDD",
    borderStyle: "solid",
    borderWidth: 5,
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: "40%",
    alignSelf: "center",
    margin: "auto",
  },
  footerText: {
    margin: "auto",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginBlock:20
  },
  logStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    justifyContent: "space-evenly",
    marginTop: "3%",
  },
  buttonContainer: {
    marginTop: "10px",
    width: "fit-content",
    backgroundColor: "#ffffff",
    borderRadius: "28px",
    paddingTop: "5px",
  },
  button: {
    backgroundColor: "#FF9900",
    color: "#000",
    border: "none",
    padding: "20px 30px",
    fontSize: "1rem",
    fontWeight: "bold",
    borderRadius: "28px",
    cursor: "pointer",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
    translate: "5% 2%",
  },
  buttonHover: {
    backgroundColor: "#cc7a00",
  },
});
