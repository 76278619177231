import { Grid } from "@mui/material";
import React from "react";
import { useStyles } from "./OfferSmoozCss";
import MediaQuery from "../../components/MediaQuery";

export default function OfferSmooz() {
  const classes = useStyles();
  let mobileView = MediaQuery()
  return (
    <Grid
      container
      component="main"
      id="offerSmooz"
      className={!mobileView ? "mobileBg3" : "background3"}
      style={{ backgroundColor: "#DDDDDD", padding: '5%', }}
    >
      <Grid
        item
        xs={12}
        lg={12}
        style={{ display: "flex", flexDirection: "column", justifyContent: mobileView ? 'center' : 'flex-end', alignItems: mobileView ? 'flex-end' : 'center' }}
      >
        <Grid className={classes.clubDateConnect}>
          Offer Smooz
        </Grid>
        <Grid lg={4} sx={6} >
          <p style={{fontSize:'0.8rem'}} >
            Let us tell you a secret..
            <br/>
            People love free drinks! Also...It's hard to say no to someone, when there's a drink in hand. (extra points for efforts yay)
            <br/>
            So, if you’re ready to find your match, why wait? Offer them a smooz today and let the loooove unfold!
          </p>
        </Grid>
        <div className={classes.buttonContainer}>
          <button className={classes.button} onMouseOver={(e) => (e.target.style.backgroundColor = classes.buttonHover.backgroundColor)} onMouseOut={(e) => (e.target.style.backgroundColor = classes.button.backgroundColor)}>
            Get Smooz
          </button>
        </div>
      </Grid>
    </Grid>
  );
}
